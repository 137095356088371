import sizeMaker from "../sizeMaker";

export default [
  {
    title: "21.010.03-АС-Ч-20-ОР547123547512354722-001-А.psd",
    status: "pen",
    author: "Новиков Николай",
    updatedAt: "30.10.2020",
    type: "acrobat",
    size: sizeMaker(5000),
    project: "21.010.03 Газго",
    updatedBy: "Жургенов Абас",
    takenBy: "Жургенов Абас",
  },
  {
    title: "21.010.03-АС-Ч-20-ОР547123547512354722-002-А.dwg",
    status: "lock",
    author: "Жургенов Абас",
    updatedAt: "30.10.2020",
    type: "autocad",
    size: sizeMaker(500000),
    project: "21.010.03 Газго",
    updatedBy: "Жургенов Абас",
    takenBy: "Жургенов Абас",
  },
  {
    title: "21.010.03-АС-Ч-20-ОР547123547512354722-003-А.dwg",
    status: "read",
    author: "Ли Сергей",
    updatedAt: "30.10.2020",
    type: "autocad",
    size: sizeMaker(500000),
    project: "21.010.03 Газго",
    updatedBy: "Ли Сергей",
    takenBy: "Ли Сергей",
  },
  {
    title: "Чертеж 5.jpg",
    status: "pen",
    author: "Новиков Николай",
    updatedAt: "30.10.2020",
    type: "image",
    size: sizeMaker(1000),
    project: "21.010.03 Газго",
    updatedBy: "Новиков Николай",
    takenBy: "Новиков Николай",
  },
];
